import request from '@/utils/request'

// 查询积分规则码列表
export function listRuleCode(query) {
  return request({
    url: '/score/rule/code/list',
    method: 'get',
    params: query
  })
}

// 查询积分规则码详细
export function getRuleCode(id) {
  return request({
    url: '/score/rule/code/' + id,
    method: 'get'
  })
}

// 新增积分规则码
export function addRuleCode(data) {
  return request({
    url: '/score/rule/code',
    method: 'post',
    data: data
  })
}

// 修改积分规则码
export function updateRuleCode(data) {
  return request({
    url: '/score/rule/code',
    method: 'put',
    data: data
  })
}

// 删除积分规则码
export function delRuleCode(id) {
  return request({
    url: '/score/rule/code/' + id,
    method: 'delete'
  })
}

export function getTabs() {
  return request({
    url: '/score/rule/code/tabs/',
    method: 'get'
  })
}

export function getTabList() {
  return request({
    url: '/score/rule/code/tabList/',
    method: 'get'
  })
}