<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="规则类型" prop="type" >
        <a-select style="width: 240px" v-model="form.type" placeholder="请选择类型"
                  :options="scriptRuleTypes" @change="selectScriptRuleTypesHandleChange"></a-select>
      </a-form-model-item>
      <a-form-model-item label="规则描述" prop="ruleDesc" >
        <a-input style="width: 240px" v-model="form.ruleDesc" placeholder="请输入内容" />
      </a-form-model-item>
      <a-form-model-item label="动作码" prop="actionCode" >
        <a-input style="width: 240px" v-model="form.actionCode" placeholder="请输入动作码" />
      </a-form-model-item>
      <a-form-model-item label="运算操作" prop="oper" >
          <a-select style="width: 240px" v-model="form.oper"  placeholder="请选择运算操作">
              <a-select-option value="+">+</a-select-option>
              <a-select-option value="-">-</a-select-option>
              <a-select-option value="*">*</a-select-option>
              <a-select-option value="/">/</a-select-option>
          </a-select>
      </a-form-model-item>
      <a-form-model-item label="分值" prop="score" >
          <a-input-number style="width: 240px" v-model="form.score" placeholder="请输入分值" />
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRule, addRule, updateRule } from '@/api/score/rule'
import { getTabList} from '@/api/score/ruleCode'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      scriptRuleTypes: [],
      // 表单参数
      form: {
        id: null,

        type: null,

        ruleDesc: null,

        actionCode: null,

        oper: null,

        score: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          { required: true, message: '规则类型不能为空', trigger: 'change' }
        ],

        ruleDesc: [
          { required: true, message: '规则描述不能为空', trigger: 'blur' }
        ],

        actionCode: [
          { required: true, message: '动作码不能为空', trigger: 'blur' }
        ],

        oper: [
          { required: true, message: '请选择运算操作', trigger: 'blur' }
        ],

        score: [
          { required: true, message: '分值不能为空', trigger: 'blur' }
        ]

      }
    }
  },
  filters: {
  },
  created () {
    this.initScriptRuleTypes()
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,

        type: null,

        ruleDesc: null,

        actionCode: null,

        oper: null,

        score: null

      }
    },
    initScriptRuleTypes() {
      getTabList().then(res => {
        this.scriptRuleTypes = res.data
      })
    },
    selectScriptRuleTypesHandleChange(value) {
      this.form.type = value
    },
    /** 新增按钮操作 */
    handleAdd () {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRule(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateRule(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addRule(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
